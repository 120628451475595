<template>
  <div class="common-bg page-container">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="form.num"
          label="兑换数量"
          placeholder="兑换数量"
          :rules="[{ required: true, message: '请输入兑换数量' }]"
        />
		<van-field
		  v-model="password"
		  type="password"
		  maxlength="6"
		  name="password"
		  label="支付密码"
		  placeholder="请输入支付密码"
		  readonly
		  clickable
		  @click="clickField(1)"
		/>
      </van-cell-group>
      <div class="text">
        <div class="price">当前价格 {{score_exchange_ticket}}</div>

        <div class="jifen">消费积分 {{user.consume_score}}</div>
      </div>
      <div class="rate">当前费率 5%</div>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          立即兑换
        </van-button>
      </div>
    </van-form>
		<van-number-keyboard
		  v-model="password"
		  :show="show1"
		  random-key-order
		  :maxlength="6"
		  @blur="show1 = false"
		/>
  </div>

</template>

<script>
import {
  getUserProductList,
  getProductDetail,
  exchange,
  checkPayPwdIsCorrect,
  getexchangeType,
  exchangeRate,
  getUserInfo,
} from '@/utils/api.js'
export default {
  data() {
    return {
		value: '',
		show1: false,
		password: '',
    user:{},
      title: '积分兑换仓单',
      score_exchange_ticket:0,
      form: {},
      showPicker: false,
      currencyInfo: {
        num: 0,
        money: '0.00',
        exchange_type: 2,
      }
    }
  },
created() {
   this.getColumns(),
  this.getexchangeRate(),
  this.getUser()
  },

   methods: {
	  clickField(type) {
	    if(type === 1) {
	      this.show1 = true
	      this.password = ''
	    }
		},
    getUser() {
      getUserInfo().then((res) => {
        if (res.code === 200) {
          this.user = res.data.user
          this.isSign = res.data.isSign
          this.userMoney = res.data.user.money
          this.totalZiChan = res.data.totalZiChan
          this.todayProfit = res.data.todayProfit
          this.totalProfit = res.data.totalProfit
          this.canCashMoney = res.data.canCashMoney
          this.totalAmount = res.data.totalAmount
        }
      })
    },
    onSubmit() {
      let { exchange_type, id } = this.currencyInfo
      let { num, mobile } = this.form
      let form = {
        num: Number(num),
        mobile,
        exchange_type,
        id
      }
	  if (this.password === '') {
	    this.$toast('请输入支付密码')
	    return
	  }
	  const param = { payPassword: this.password }
	  checkPayPwdIsCorrect(param).then(res => {
	    if (res.code === 200) {
	      this.$toast.success({
	        message: res.message,
	        duration: 1500,
	        onClose: () => {
	          this.onClickLeft()
	        }
	      })
	    } else {
	      this.$toast(res.message)
	    }
	  }),
      exchange(form).then((res) => {
        this.$toast(res.message)
        this.onClickLeft()
      })
    },

  getColumns() {
      getexchangeType().then((res) => {
        this.columns = res.data
        this.currencyInfo = {
        exchange_type,
          id
        }
      })
    },
    getexchangeRate() {
       exchangeRate().then((res) => {
          this.score_exchange_ticket = res.data.score_exchange_ticket
        })
      },
    onCancel() {
      this.showPicker = false
    },

    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  ::v-deep .van-field__control {
    text-align: right;
  }
}
.text{
  display: flex;
  margin-top: 10px;
  font-weight: 10px;
  .jifen{
    margin-left: 140px;
  }
  .price{
    margin-left: 20px;
  }
}
.rate{
  margin-left: 20px;
}
</style>
